import { graphql } from 'gatsby';
import React from 'react';
import HeroOrHeading from '../components/HeroOrHeading';
import TextSection from '../blocks/TextSection';
import Banner from '../blocks/Banner';
import CardList from '../blocks/CardList';
import SEO from '../components/SEO';

export default function HomePage(props) {
  const { data = {} } = props;
  const { name, hero, content, content2, verticalCards, banner } =
    data.homepage.nodes[0] || {};

  return (
    <>
      <SEO title="Home" />
      {hero && <HeroOrHeading hero={hero} name={name} />}
      {content && <TextSection content={content} />}
      {banner?.length > 0 && <Banner banner={banner[0]} />}
      {content2 && <TextSection content={content2} />}
      {verticalCards?.length > 0 && <CardList cards={verticalCards} />}
    </>
  );
}

export const query = graphql`
  query HomepageQuery {
    # renames query
    homepage: allSanityHomepage {
      nodes {
        name
        hero {
          ... on SanityHeroWithText {
            name
            description
            image {
              asset {
                url
              }
            }
          }
          ... on SanityAccessibleImage {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
        content {
          name
          _rawContent(resolveReferences: { maxDepth: 10 })
          link {
            link
            url
          }
          link2 {
            url
            link
          }
        }
        content2 {
          name
          _rawContent(resolveReferences: { maxDepth: 10 })
          link {
            link
            url
          }
          link2 {
            url
            link
          }
        }
        banner {
          name
          description
          link {
            link
            url
          }
        }
        verticalCards {
          id
          name
          description
          link
          image {
            image {
              asset {
                fluid(maxHeight: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`;

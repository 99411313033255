import React from 'react';
import styled from 'styled-components';
import madras from '../assets/images/vi-madras-vichf.svg';

const BannerStyles = styled.section`
  margin-bottom: 3rem;
  margin-right: 1rem;
  padding: 2rem;
  background: var(--teal);
  position: relative;

  @media (min-width: 768px) {
    margin-bottom: 5rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url(${madras}) no-repeat transparent;
    background-size: cover;
    transform: translate(1rem, 1rem);

    @media (min-width: 768px) {
      transform: translate(2rem, 2rem);
    }
  }

  h2,
  a,
  p {
    color: var(--white);
  }

  h2,
  p {
    max-width: 800px;
  }

  p {
    width: 100%;
  }

  h2 {
    margin-bottom: 2rem;
  }

  a {
    display: block;
    text-align: right;
  }
`;

function IsLinked({ link }) {
  if (link.link) {
    return <a href={link.url}>{link.link}</a>;
  }
  return <a href={link.url}>{link.url}</a>;
}

export default function Banner({ banner }) {
  const { name, description, link } = banner || {};
  return (
    // accepts a heading, link, and optional long text
    <BannerStyles>
      {name && <h2>{name}</h2>}
      {description && <p>{description}</p>}
      {link && <IsLinked link={link} />}
    </BannerStyles>
  );
}
